import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import { motion } from "framer-motion"
import { motionItem } from "../utils/motion"
import Breakpoints from "../utils/breakpoints"

import SeparatedText from "../components/separatedText"
import SkillList from "../components/skillList"
import Hover from "../components/hover"
import Button from "../components/button"
import Partner from "../components/partner"

const H1 = styled.h1`
  font-size: 2.1rem;
  margin: 0 0 1rem 0;
  @media (min-width: ${Breakpoints.tablet}px) {
    font-size: 4.4rem;
  }
  @media (min-width: ${Breakpoints.desktop}px) {
    font-size: 6.8rem;
  }
`
const H2 = styled.h2`
  display: flex;
  gap: 1em;
  > div {
    font-size: 1.5rem;
  }
  margin-bottom: 2rem;
  @media (max-width: ${Breakpoints.tablet}px) {
    flex-direction: column;
    gap: 0.4em;
    > div:not(:last-child) {
      position: relative;
      padding-bottom: 0.4em;
    }
    > div:not(:last-child):after {
      content: "";
      width: 47px;
      height: 3px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #000;
    }
  }
  @media (min-width: ${+Breakpoints.tablet + 1}px) {
    > div {
      font-size: 1.4rem;
    }
  }
  @media (min-width: ${Breakpoints.desktop}px) {
    > div {
      font-size: 2rem;
    }
  }
`

const ServicesList = styled.ul`
  display: flex;
  @media (max-width: ${Breakpoints.tablet}px) {
    flex-direction: column;
    gap: 0.5em;
  }
  flex-wrap: wrap;
  padding: 0;
`

const ServicesListItem = styled.li`
  font-size: 1.1rem;
  line-height: 1.4rem;
  list-style-type: none;
  flex: 1;
  @media (min-width: ${+Breakpoints.tablet + 1}px) {
    font-size: .8rem;
    line-height: 1rem;
    :not(:last-child) {
      border-right: #000 1px solid;
      margin-right: 0.7em;
      padding-right: 0.7em;
    }
  }
  @media (min-width: ${Breakpoints.desktop}px) {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
`

const Hero = styled.div`
  @media (min-width: ${+Breakpoints.mobile + 1}px) {
    cursor: none !important;
  }
`

const BoldText = styled.span`
  font-weight: 700;
`

const Index = () => {
  return (
    <>
      <Seo title="Webentwicklung" />
      <Hero>
        <motion.div variants={motionItem}>
          <H1>
            <Hover
              dataCursor={{
                animation: true,
                gooColor: "#ffffff",
                gooSize: "180px",
                colorPointer: "#ffffff",
                blendMode: "difference",
                overlay: true,
              }}
            >
              <SeparatedText>
                Hallo,|ich bin Julian,|Webentwickler.
              </SeparatedText>
            </Hover>
          </H1>
        </motion.div>
        <motion.div>
          <H2>
            <div>Front-End-Spezialist</div>
            <div>Wordpress-Experte</div>
            <div>Freelancer</div>
          </H2>
        </motion.div>
        <motion.div>
          <ServicesList>
            <ServicesListItem>
              <BoldText>Erstellung, Betreuung und Wartung</BoldText> von
              Webauftritten
            </ServicesListItem>
            <ServicesListItem>
              <BoldText>Kein langes Warten</BoldText> durch schnelle und
              unkomplizierte Bearbeitung
            </ServicesListItem>
            <ServicesListItem>
              Pixelgenaue, <BoldText>moderne und responsive Webseiten und Web-Apps</BoldText>
            </ServicesListItem>
            <ServicesListItem>
              Agenturerfahrung und <BoldText>Netzwerk</BoldText> aus Agenturen
              und Grafiker*innen
            </ServicesListItem>
            <ServicesListItem>
              Animierte und optimierte <BoldText>HTML 5 Banner</BoldText>
            </ServicesListItem>
          </ServicesList>
        </motion.div>
        <motion.div>
          <Button to="/kontakt">Unverbindliche Anfrage</Button>
        </motion.div>
      </Hero>
      <SkillList height="50vh" />
      <Partner />
    </>
  )
}

export default Index
