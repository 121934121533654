import styled, { keyframes } from "styled-components"

const rubbAnimation = () => keyframes`
from {
  transform: scale3d(1, 1, 1);
}

30% {
  transform: scale3d(1.25, 0.75, 1);
}

40% {
  transform: scale3d(0.75, 1.25, 1);
}

50% {
  transform: scale3d(1.15, 0.85, 1);
}

65% {
  transform: scale3d(0.95, 1.05, 1);
}

75% {
  transform: scale3d(1.05, 0.95, 1);
}

to {
  transform: scale3d(1, 1, 1);
}
`
const Rubbing = styled.div`
  display: inline-block;
  padding-right: 3px;
  cursor: pointer;
  :empty {
    width: 0.2em;
  }
  :hover {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-name: ${rubbAnimation};
  }
`
export default Rubbing;