import React from "react"
import styled, { keyframes } from "styled-components"

const shineAnimation = () => keyframes`
    0% {
        color: var(--color-second);
        text-shadow: none;
    }
    100% {
        color: var(--color-second);
        text-shadow: 0 0 0.6rem var(--color-second), 0 0 1.5rem var(--color-second),
            -0.2rem 0.1rem 1rem var(--color-second), 0.2rem 0.1rem 1rem var(--color-second),
            0 -0.5rem 2rem var(--color-second), 0 0.5rem 3rem var(--color-second);
    }
`

const blinkAnimation = () => keyframes`
    0%,
    22%,
    36%,
    75% {
    color: var(--color-main);
    text-shadow: 0 0 0.6rem var(--color-main), 0 0 1.5rem var(--color-main),
        -0.2rem 0.1rem 1rem var(--color-main), 0.2rem 0.1rem 1rem var(--color-main),
        0 -0.5rem 2rem var(--color-main), 0 0.5rem 3rem var(--color-main);
    }
    28%,
    33% {
    color: var(--color-main);
    text-shadow: none;
    }
    82%,
    97%,100% {
    color: var(--color-main);
    text-shadow: none;
    }
`

const flickerAnimation = () => keyframes`
from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
`

const Sign = styled.div`
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: 2rem;
  color: var(--color-second);
  text-shadow: 0 0 0.6rem var(--color-second), 0 0 1.5rem var(--color-second),
    -0.2rem 0.1rem 1rem var(--color-second),
    0.2rem 0.1rem 1rem var(--color-second), 0 -0.5rem 2rem #ff2483,
    0 0.5rem 3rem #ff2483;
  animation: ${shineAnimation} 2s forwards, ${flickerAnimation} 3s infinite;
  .flicker {
    animation: ${shineAnimation} 2s forwards, blink 3s 2s infinite;
  }

  .fast-flicker {
    animation: ${shineAnimation} 2s forwards, ${blinkAnimation} 10s 1s infinite;
  }
  .fast-flicker:nth-of-type(2n + 1) {
    animation-delay: 0.5s;
  }
`

const Attention = () => {
  return (
    <Sign>
      <span className="fast-flicker">A</span>t<span className="fast-flicker">t</span>e
      <span className="fast-flicker">n</span>t<span className="fast-flicker">i</span>o
      <span className="fast-flicker">n</span>
    </Sign>
  )
}

export default Attention
