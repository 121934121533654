import React from "react"
import uniqid from "uniqid"

import Skill from "./skill"
import Responsive from "../animation/responsive"
import BouncingText from "../animation/bouncingText"
import Jamstack from "../animation/jamstack"
import Gear from "../components/gear"
import Attention from "../animation/attention"


const skills = [
  {
    id: 0,
    headline: "Full Stack",
    subline: "Frontend- & Backend-Entwicklung",
    text: `Egal, ob simple Frontend-Darstellung oder komplizierte
    Anwendungen mit individuellem Backend, ich kümmere mich um die
    technischen Details und Sie können sich ganz Ihrem Geschäft
    widmen.`,
    icon: <Gear />,
    bgColor:"#e2e2e2"
  },
  {
    id: 1,
    headline: "State of the art",
    subline: "Moderne Technologien für verlässliche Anwendungen",
    text: `Optimale Ladezeit, verbessertes Suchmaschinen-Ranking, Sicherheit durch statisches Frontend und unkomliziertes Skalieren. Nutzen Sie Ihr gewohntes oder bevorzugtes CMS und präsentieren Sie Ihre Daten in modernem Look & Feel.`,
    icon: <Jamstack />,
    bgColor:"#e2e2e2"
  },
  {
    id: 2,
    headline: "Geräteunabhängig",
    subline: "Smartphone, Tablet & Desktop",
    text: `Sie haben bereits ein Design-Mockup, ich sorge für die pixelgenaue Darstellung auf jedem Endnutzergerät. Dabei lege ich besonderes Augenmerk auf die Smartphone-Darstellung und eine intuitive Handhabung durch die Anwender*innen.`,
    icon: <Responsive />,
    bgColor:"#e2e2e2"
  },
  {
    id: 3,
    headline: "Animation & Micro-Actions",
    subline: "CSS, SVG, Javascript",
    text: `Ich erstelle, speziell nach Ihren Wünschen angepasste, Effekte und Animationen. Gerne arbeite ich nach Ihren Vorgaben und setze Ihr Corporate Design in Szene.`,
    icon: <BouncingText />,
    bgColor:"#e2e2e2"
  },
  {
    id: 4,
    headline: "POP Out",
    subline: "Kundenbindung durch auffallende und einprägende Darstellung",
    text: `Gemeinsam erarbeiten wir ein Konzept, Ihre Anwendung oder Produkte in Szene zu setzen und den Anwender*innen ein unvergessliches Erlebnis zu bieten.`,
    icon: <Attention/>,
    bgColor:"#000"
  },
]

const SkillList = ({ height }) => {
  return (
    <div className="skill-list__wrapper">
      {skills.map(function (skill) {
        return (
          <Skill
            key={uniqid()}
            headline={skill.headline}
            subline={skill.subline}
            text={skill.text}
            icon={skill.icon}
            height={height}
            bgColor={skill.bgColor}
          />
        )
      })}
    </div>
  )
}

export default SkillList
