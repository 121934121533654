import React from "react"

import styled, { keyframes, css } from "styled-components"
import { useInView } from "react-intersection-observer"
import Breakpoints from "../utils/breakpoints"

import LineBg from "../animation/lineBg"

const slideInAnimation = direction => keyframes`
  0% {
    transform: translateX(${direction === "left" ? "-" : ""}200%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`
const SlideIn = styled.div`
  animation: ${props => slideInAnimation(props.direction)} 2s;
  ${props =>
    props.direction === "left" &&
    css`
      width: 60%;
    `}
  ${props =>
    props.direction === "right" &&
    css`
      @media (max-width: ${+Breakpoints.desktop - 1}px) {
        height: 100%;
        display: flex;
        align-items: center;
        min-height: 350px;
      }
      width: 40%;
      display: flex;
      justify-content: center;
    `}
    @media (max-width: ${+Breakpoints.desktop - 1}px) {
    margin-top: 5px;
    width: calc(100% - 10px);
  }
`

const SkillWrapper = styled.div`
  position: relative;
  display: flex;
  min-height: ${props => props.height};
  @media (max-width: ${+Breakpoints.desktop - 1}px) {
    min-height: 80vh;
  }
  width: 100%;
  margin-top: 1rem;
  @media (min-width: ${Breakpoints.desktop}px) {
    margin-top: 3rem;
  }
`

const SkillInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  @media (max-width: ${+Breakpoints.desktop - 1}px) {
    flex-direction: column;
  }
`

const StyledText = styled.div`
  padding: 1rem;
  background: #fff;
  @media (min-width: ${Breakpoints.desktop}px) {
    padding: 3rem;
  }
`
const H3 = styled.h3`
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
  @media (min-width: ${Breakpoints.desktop}px) {
    margin-top: 2rem;
    font-size: 1.5rem;
  }
  font-weight: 400;
  text-transform: uppercase;
`

const P = styled.p`
  @media (max-width: ${+Breakpoints.desktop - 1}px) {
    margin-bottom: 0;
  }
`

const Subline = styled.div`
  font-weight: 700;
`

const Skill = ({ headline, subline, text, icon, height, bgColor }) => {
  const { ref, inView } = useInView({
    threshold: 0,
  })

  return (
    <SkillWrapper ref={ref} height={height}>
      {inView && (
        <>
          <SkillInnerWrapper>
            <SlideIn direction="left">
              <StyledText>
                <H3>{headline}</H3>
                <Subline>{subline}</Subline>
                <P>{text}</P>
              </StyledText>
            </SlideIn>
            <SlideIn direction="right">{icon}</SlideIn>
          </SkillInnerWrapper>
          <LineBg bgColor={bgColor}></LineBg>
        </>
      )}
    </SkillWrapper>
  )
}

export default Skill
