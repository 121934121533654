import styled, { keyframes } from "styled-components"

import Gear from "../svg/gear.svg"

const rotate = () => keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`
const rotateRev = () => keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(-360deg);
}
`

const StyledGear = styled(Gear)`
  #jib-gear-small-1 {
    fill: var(--color-second);
    stroke-width: 2px;
    animation: ${rotate} 5s linear infinite;
    transform-origin: 110px 62.5px;
  }
  #jib-gear-small-2 {
    fill: var(--color-main);
    stroke-width: 2px;
    animation: ${rotate} 5s linear infinite;
    transform-origin: 364px 245px;
  }
  #jib-gear-big {
    fill: #fff;
    stroke-width: 2px;
    animation: ${rotateRev} 21s linear infinite;
    transform-origin: center;
  }
`

export default StyledGear