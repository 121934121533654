import React from "react"
import { Link } from "gatsby"
import Hover from "./hover"
import styled from "styled-components"
import Breakpoints from "../utils/breakpoints"

const StyledButton = styled.button`
  background: #000000;
  border: #000000 3px solid;
  margin-top: 2rem;
  padding: 0.6rem 2rem;
  transition: all 1s ease-out;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1rem;
  letter-spacing: 1px;
  width: 100%;
  @media (min-width: ${+Breakpoints.mobile+1}px) {
    font-size: 1.5rem;
    letter-spacing: 2px;
    width: auto;
    &:hover {
      padding: 0.6rem 3rem;
    }
  }
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
`
const StyledLink = styled(Link)`
  display: inline-block;
  width: 100%;
`

const Button = ({ to, children }) => {
  return (
    <StyledLink to={to}>
      <Hover
        dataCursor={{
          animation: true,
          gooColor: "#ffffff",
          gooSize: "120px",
          colorPointer: "#ffffff",
          blendMode: "difference",
          overlay: true,
        }}
        clickExit
      >
        <StyledButton>{children}</StyledButton>
      </Hover>
    </StyledLink>
  )
}

export default Button
