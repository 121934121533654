import React from "react"
import Rubbing from "./rubbing"
import uniqid from 'uniqid';
import Breakpoints from "../utils/breakpoints";

import { useMediaQuery } from "react-responsive"

const SeparatedDivs = ({ text }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${Breakpoints.mobile}px)` })
  const lines = text.split("|")
  return lines.map((line) => {
    if(isMobile) {
      return (<>{line}<br/></>)
    }
    const chars = line.split("")
    return (
      <div key={uniqid()}>
        {chars.map((char) => {
          return (
            <Rubbing key={uniqid()}>
              {char !== " " ? char : ""}
            </Rubbing>
          )
        })}
      </div>
    )
  })
}

const SeparatedText = ({ children }) => {
  return <SeparatedDivs text={children}></SeparatedDivs>
}

export default SeparatedText
