import React from "react"

import { motion } from "framer-motion"
import styled from "styled-components"

const LineBgWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  circle,
  rect,
  line {
    stroke-width: 10px;
    stroke-linecap: round;
    fill: transparent;
  }
`

const LineBg = ({bgColor}) => {
  return (
    <LineBgWrapper>
      <motion.svg
        width="100%"
        height="100%"
        viewBox="0 0 100% 100%"
        initial="hidden"
        animate="visible"
      >
        <motion.rect
          width="100%"
          height="100%"
          x="0"
          y="0"
          rx="0"
          stroke="#000000"
          initial={{
            pathLength: 0,
            opacity: 0,
            style: "fill:#ffffff; transition:all 5s ease-out",
          }}
          animate={i => {
            //const delay = 1 + i * 0.5
            const delay = 0.1
            return {
              pathLength: 1,
              opacity: 1,
              style:  `fill:${bgColor};transition:all 5s ease-out`,
              transition: {
                pathLength: {
                  delay,
                  type: "spring",
                  duration: 0.5,
                  bounce: 0,
                },
                opacity: { delay, duration: 0.01 },
              },
            }
          }}
          custom={3}
        />
      </motion.svg>
    </LineBgWrapper>
  )
}

export default LineBg
