import React from "react"
import styled from "styled-components"
import Breakpoints from "../utils/breakpoints"
import Hover from "./hover"
import uniqid from "uniqid"

import BSLogo from "../images/clients/logos/julianberger_kunden_business-support_logo.png"
import DDLogo from "../images/clients/logos/julianberger_kunden_designerdock_logo.png"
import PRLLogo from "../images/clients/logos/julianberger_kunden_partnering_logo.jpg"
import PLogo from "../images/clients/logos/julianberger_kunden_proske_logo.png"
import WLogo from "../images/clients/logos/julianberger_kunden_wundermild_logo.png"
import ArtLogo from "../images/clients/logos/julianberger_kunden_arthouse-project_logo.jpg"
import KokumuLogo from "../images/clients/logos/julianberger_kunden_kokumu_logo.png"
import MagnidLogo from "../images/clients/logos/julianberger_kunden_magnid_logo.png"

const data = [
  {
    link: "https://www.business-support.at/",
    color: "#fe5000",
    logo: <img src={BSLogo} alt="Business Support" />,
  },
  {
    link: "https://www.wundermild.at/",
    color: "#f08482",
    logo: <img src={WLogo} alt="Wundermild" />,
  },
  {
    link: "http://www.arthouse-project.com/",
    color: "#D31616",
    logo: <img src={ArtLogo} alt="Art House Project" />,
  },
  {
    link: "https://partnering.one/",
    color: "#0099CC",
    logo: <img src={PRLLogo} alt="Partnering" />,
  },
  {
    link: "https://www.kochkulturmuseum.at/",
    color: "#e72512",
    logo: <img src={KokumuLogo} alt="Koch Kultur Museum" />,
  },
  {
    link: "https://designerdock.com/ueber-uns/muenchen",
    color: "#b1c110",
    logo: <img src={DDLogo} alt="Designerdock" />,
  },
  {
    link: "https://www.proske.com/",
    color: "#0bb5cb",
    logo: <img src={PLogo} alt="Proske" />,
  },
  {
    link: "https://www.magnid.com/",
    color: "#F70041",
    logo: <img src={MagnidLogo} alt="Magnid" />,
  },
]

const PartnerWrapper = styled.div`
  position: relative;
  margin-top: 2rem;
`

const PartnerContainer = styled.div`
background: #e2e2e2;
padding: 2rem;
display: flex;
gap: 1.5rem;
flex-direction: column;
@media (min-width: ${Breakpoints.desktop}px){
  flex-direction: row;
  padding: 6rem 3rem;
  gap: 3rem;
}
`
const PartnerImage = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 70px;
    width: 60%;
    @media (min-width: ${Breakpoints.desktop}px) {
      width: 100%;
      height: auto;
      filter: grayscale(100%);
    }
    mix-blend-mode: ${props => (props?.noblend ? "none" : "multiply")};
    object-fit: contain;
    &:hover {
      filter: none;
    }
  }
`

const Title = styled.h2`
  color: #e2e2e2;
  font-size: 1.5rem;
  @media (min-width: ${Breakpoints.desktop}px) {
    font-size: 3rem;
  }
  width: 100%;
  text-align: center;
  padding: 1rem 1rem 0 1rem;
  text-transform: uppercase;
`

const HoverWithCenteredContent = styled(Hover)`
  display: flex;
  justify-content: center;
`

const Partner = () => {
  return (
    <PartnerWrapper>
      <Title id="kunden">Kund:innen und Partner:innen</Title>
      <PartnerContainer>
        {data.map(element => {
          return (
            <PartnerImage key={uniqid()}>
              <a href={element.link} target="_blank" rel="noreferrer">
                <HoverWithCenteredContent
                  dataCursor={{
                    animation: true,
                    gooColor: element.color,
                    gooSize: "30px",
                    colorPointer: "#ffffff",
                    blendMode: "none",
                    overlay: true,
                  }}
                >
                  {element.logo}
                </HoverWithCenteredContent>
              </a>
            </PartnerImage>
          )
        })}
      </PartnerContainer>
    </PartnerWrapper>
  )
}

export default Partner
