import React from "react"
import styled, { keyframes } from "styled-components"
import uniqid from 'uniqid';

const bounceAnimation = () => keyframes`
100% {
    top: -20px;
    text-shadow: 0 1px 0 var(--color-main),
                 0 2px 0 var(--color-main),
                 0 3px 0 var(--color-main),
                 0 4px 0 var(--color-main),
                 0 5px 0 var(--color-main),
                 0 6px 0 transparent,
                 0 7px 0 transparent,
                 0 8px 0 transparent,
                 0 9px 0 transparent,
     0 10px 0 transparent,
                 0 11px 0 transparent,
                 0 12px 0 transparent,
                 0 13px 0 transparent,
     0 14px 0 transparent,
                 0 15px 0 transparent,
                 0 50px 25px rgba(255, 255, 255, .2);
  }
`
const bounceAnimation2 = () => keyframes`
100% {
    top: -20px;
    text-shadow: 0 1px 0 var(--color-second),
                 0 2px 0 var(--color-second),
                 0 3px 0 var(--color-second),
                 0 4px 0 var(--color-second),
                 0 5px 0 var(--color-second),
                 0 6px 0 transparent,
                 0 7px 0 transparent,
                 0 8px 0 transparent,
                 0 9px 0 transparent,
     0 10px 0 transparent,
                 0 11px 0 transparent,
                 0 12px 0 transparent,
                 0 13px 0 transparent,
     0 14px 0 transparent,
                 0 15px 0 transparent,
                 0 50px 25px rgba(255, 255, 255, .2);
  }
`
const BouncingTextWrapper = styled.div`
  text-align: center;
`

const BouncingTextSpan = styled.span`
  position: relative;
  top: 20px;
  font-size: 30px;
  font-weight: 700;
  padding-right: 5px;
  text-transform: uppercase;
  display: inline-block;
  animation: ${bounceAnimation} 1s ease infinite alternate;
  color: #fff;
  text-shadow: 0 1px 0 var(--color-main), 0 2px 0 var(--color-main),
    0 3px 0 var(--color-main), 0 4px 0 var(--color-main),
    0 5px 0 var(--color-main), 0 6px 0 transparent, 0 7px 0 transparent,
    0 8px 0 transparent, 0 9px 0 transparent, 0 10px 0 transparent,
    0 11px 0 transparent, 0 12px 0 transparent, 0 13px 0 transparent,
    0 14px 0 transparent, 0 15px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  &:nth-child(2n + 1) {
    text-shadow: 0 1px 0 var(--color-second), 0 2px 0 var(--color-second),
      0 3px 0 var(--color-second), 0 4px 0 var(--color-second),
      0 5px 0 var(--color-second), 0 6px 0 transparent, 0 7px 0 transparent,
      0 8px 0 transparent, 0 9px 0 transparent, 0 10px 0 transparent,
      0 11px 0 transparent, 0 12px 0 transparent, 0 13px 0 transparent,
      0 14px 0 transparent, 0 15px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
    animation: ${bounceAnimation2} 1s ease infinite alternate;
  }
  &:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  &:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  &:nth-child(4) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  &:nth-child(5) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  &:nth-child(6) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  &:nth-child(7) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  &:nth-child(8) {
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }
  &:nth-child(9) {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }
  &:nth-child(10) {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }
  &:nth-child(11) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  &:nth-child(12) {
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s;
  }
  &:nth-child(13) {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
  }
  &:nth-child(14) {
    -webkit-animation-delay: 1.3s;
    animation-delay: 1.3s;
  }
`

const string = "Animation"
const BouncingText = () => {
  return (
    <BouncingTextWrapper>
      {string.split("").map((char) => {
        return <BouncingTextSpan key={uniqid()}>{char}</BouncingTextSpan>
      })}
    </BouncingTextWrapper>
  )
}

export default BouncingText
