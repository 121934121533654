import React from "react"
import styled, { keyframes } from "styled-components"

const colorAnimation = () => keyframes`
0% {
  background: var(--color-second);
}
33% {
  background: var(--color-main);
}
100% {
  background: var(--color-second);
}
`
const liquidAnimation = () => keyframes`
0% {
  transform: translate(-50%, -75%) rotate(0deg);
}
100% {
  transform: translate(-50%, -75%) rotate(360deg);
}
`
const responsiveAnimation = () => keyframes`
0% {
}
33% {
  width: calc(var(--liquid-width) * .8);
  height: calc(var(--liquid-width) * var(--liquid-ratio) * 1.5);
  font-size: 20px;
}
66% {
  width: calc(var(--liquid-width) * .7);
  height: calc(var(--liquid-width) * var(--liquid-ratio) * .8);
  font-size: 16px;
}
100% {
}
`

const ResponsiveWrapper = styled.div`
  &:before {
    pointer-events: none;
    position: absolute;
    z-index: 995;
    content: "";
    bottom: 0;
    transform: translateY(200%);
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: -webkit-radial-gradient(
      center,
      ellipse,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0) 80%
    );
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0) 80%
    );
    transition-duration: 0.3s;
    transition-property: transform, opacity;
  }
`

const ResponsiveContainer = styled.div`
  position: relative;
  padding: 20px 50px;
  display: flex;
  text-decoration: none;
  text-transform: uppercase;
  width: var(--liquid-width);
  height: calc(var(--liquid-width) * var(--liquid-ratio));
  overflow: hidden;
  transition: all 1s ease;
  /*border: #333 2px solid;*/
  align-items: center;
  font-size: 24px;
  animation: ${responsiveAnimation} 6s ease-out infinite;
  border-radius: 15px;
`

const ResponsiveText = styled.div`
  position: relative;
  color: #333;
  font-family: Arial;
  letter-spacing: 5px;
  text-align: center;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  padding-left: 5px;
`

const ResponsiveLiquid = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: var(--liquid-width);
  height: var(--liquid-width);
  background: var(--liquid-color);
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
  transition: 0.5s;
  transform: translateY(30%);
  animation: ${colorAnimation} 10s linear infinite;
  &:after,
  :before {
    content: "";
    width: 200%;
    height: 200%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -75%);
  }
  &:before {
    border-radius: 45%;
    background: rgba(255, 255, 255, 1);
    animation: ${liquidAnimation} 5s linear infinite;
  }
  &:after {
    border-radius: 40%;
    background: rgba(255, 255, 255, 0.5);
    animation: ${liquidAnimation} 10s linear infinite;
  }
`

const Responsive = () => {
  return (
    <ResponsiveWrapper>
      <ResponsiveContainer>
        <ResponsiveText>RESPONSIVE</ResponsiveText>
        <ResponsiveLiquid />
      </ResponsiveContainer>
    </ResponsiveWrapper>
  )
}

export default Responsive
