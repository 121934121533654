import React from "react"

import GatsbyLogo from "../svg/gatsby.svg"
import WordpressLogo from "../svg/wordpress.svg"
import ContentFulLogo from "../svg/contentful.svg"
import JsLogo from "../svg/js.svg"
import TsLogo from "../svg/ts.svg"
import RLogo from "../svg/rlogo.svg"
import Shopify from "../svg/shopify.svg"
import styled, { keyframes } from "styled-components"

const spinAnimation = () => keyframes`
100% {
    transform:rotate(1turn) translate(100px) rotate(-1turn);
  }
  `
const JamstackWrapper = styled.div`
  svg {
    width: 40px;
    height: 40px;
  }
`
const JamstackContainer = styled.div`
  width: 200px;
  height: 200px;
  margin: 40px auto;
  display: grid;
  grid-template-columns: 40px;
  grid-template-rows: 40px;
  place-content: center;
  border-radius: 50%;
`

const JamstackItem = styled.div`
  grid-area: 1/1;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  animation: ${spinAnimation} 14s ${props => props.delay} linear infinite; /* duration = 12s, numbor of item = 6 so a delay of 12/6 = 2s */
  transform: rotate(0) translate(100px) rotate(0);
`

const Jamstack = () => {
  return (
    <JamstackWrapper>
      <JamstackContainer>
        <JamstackItem delay="0s"><GatsbyLogo /></JamstackItem>
        <JamstackItem delay="-2s"><WordpressLogo /></JamstackItem>
        <JamstackItem delay="-4s"><ContentFulLogo /></JamstackItem>
        <JamstackItem delay="-6s"><JsLogo /></JamstackItem>
        <JamstackItem delay="-8s"><RLogo /></JamstackItem>
        <JamstackItem delay="-10s"><Shopify /></JamstackItem>
        <JamstackItem delay="-12s"><TsLogo /></JamstackItem>
      </JamstackContainer>
    </JamstackWrapper>
  )
}

export default Jamstack
